export const onClientEntry = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const p = urlParams.get("p");

  if (
    window.location.pathname != `/servizi/utenza-professionale-rinnovo-contratto` &&
    window.location.pathname != `/servizi/utenza-professionale-rinnovo-contratto/` &&
    p &&
    p == "us004"
  ) {
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.host +
      "/servizi/utenza-professionale-rinnovo-contratto";
  } else if (
    /^en\b/.test(navigator.language) &&
    !window.location.pathname.startsWith("/en") &&
    window.location.pathname == "/" &&
    window.location.hostname != "localhost"
  ) {
    window.location.pathname = `/en`;
  }
};
